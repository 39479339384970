import { Config } from ".";

const config: Config = {
  strapiUrl: "https://5f4xsjrfjfhy6hhmt3zlnqzlgi0ubepf.lambda-url.ap-east-1.on.aws/api",
  invoiceDataTable: {
    idPlaces: 6
  },
};

export default config;
